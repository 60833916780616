exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-bugs-jsx": () => import("./../../../src/pages/bugs.jsx" /* webpackChunkName: "component---src-pages-bugs-jsx" */),
  "component---src-pages-changelog-jsx": () => import("./../../../src/pages/changelog.jsx" /* webpackChunkName: "component---src-pages-changelog-jsx" */),
  "component---src-pages-configuration-jsx": () => import("./../../../src/pages/configuration.jsx" /* webpackChunkName: "component---src-pages-configuration-jsx" */),
  "component---src-pages-contribution-jsx": () => import("./../../../src/pages/contribution.jsx" /* webpackChunkName: "component---src-pages-contribution-jsx" */),
  "component---src-pages-docs-jsx": () => import("./../../../src/pages/docs.jsx" /* webpackChunkName: "component---src-pages-docs-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-installation-jsx": () => import("./../../../src/pages/installation.jsx" /* webpackChunkName: "component---src-pages-installation-jsx" */),
  "component---src-pages-issues-jsx": () => import("./../../../src/pages/issues.jsx" /* webpackChunkName: "component---src-pages-issues-jsx" */),
  "component---src-pages-more-jsx": () => import("./../../../src/pages/more.jsx" /* webpackChunkName: "component---src-pages-more-jsx" */),
  "component---src-pages-upcoming-jsx": () => import("./../../../src/pages/upcoming.jsx" /* webpackChunkName: "component---src-pages-upcoming-jsx" */)
}

